import React from 'react';
import './App.css';

class InputForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {contract: '', index: ''};
    this.onChangeContract = this.onChangeContract.bind(this);
    this.onChangeIndex = this.onChangeIndex.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeContract(event) {
    this.setState({contract: event.target.value});
  }

  onChangeIndex(event) {
    this.setState({index: event.target.value});
  }

  onSubmit(event) {
    event.preventDefault();
    if (this.props.onSubmit != null) {
      this.props.onSubmit(this.state.contract, this.state.index)
    }
  }

  render() {
    return (
      <form className="Input-Form" onSubmit={this.onSubmit}>
        <label>
          Contract ID:
          <input type="text" className="Text-Input" value={this.state.contract} onChange={this.onChangeContract} />
        </label>
        <label>
          Index:
          <input type="text" className="Text-Input" value={this.state.contract} onChange={this.onChangeIndex} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}

function App() {
  function mainSubmit(contract, index) {
    alert(contract + " " + index)
  }

  return (
    <div className="App">
      <header className="App-header">
        Nifty
      </header>
      <div className="Input">
        <InputForm onSubmit={mainSubmit}/>
      </div>
    </div>
  );
}

export default App;
